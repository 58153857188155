<template>
    <div>
        <v-title title="Configurations Overview"></v-title>

        <title-box>
            <div class="row">
                <div class="col col-9">
                    <h1>Configurations Overview</h1>
                </div>

                <div class="col col-3 text-right">
                    <mercur-button class="btn btn-primary" :to="{ name: 'AddProductFeedConfiguration' }">+ Add new configuration</mercur-button>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('listConfigurations')">
                <div class="mt-4">
                    <pagination :pagination="pagination" @change="changeAmountOfItems"></pagination>
                    <merchant-data-table
                        :options="options"
                        :url="url"
                        ref="table"
                        @paginationSet="updatePagination"
                        :isNotApplicationBound="true">
                    </merchant-data-table>
                </div>
            </template>
            <span v-else>
      Not allowed to see this view
    </span>
        </div>
        <mercur-dialog :is-open.sync="dialog.isOpen">
            {{dialog.content}}
            <template #footer>
                <mercur-button class="btn" @click="dialog.isOpen = false">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" @click="revoke">Revoke</mercur-button>
            </template>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'

export default {
    name: 'ConfigurationsOverview',
    components: { MerchantDataTable, Pagination },

    data () {
        return {
            url: CONFIG.API.ROUTES.PRODUCT_FEEDS.CONFIGURATIONS.OVERVIEW,
            isDialogOpen: false,
            activeData: null,
            pagination: {},
            amountOfDisplayedItems: 50,
            isLoading: false,
            dialog: {
                isOpen: false,
            },
            options: {
                columns: {
                    'Configuration Name': {
                        field: 'configurationName',
                        link: (value, data) => {
                            return {
                                name: 'EditConfiguration',
                                params: {
                                    configurationId: data.configurationId,
                                },
                            }
                        },
                    },
                    'Date Created': { field: 'dateCreated', filter: false },
                    'Date Updated': { field: 'dateUpdated', filter: false, sortable: true },
                },
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Edit',
                            to: ({ data }) => {
                                return {
                                    name: 'EditConfiguration',
                                    params: {
                                        configurationId: data.configurationId,
                                    },
                                }
                            },
                            isHidden: () => {
                                return !this.hasPermission('updateConfigurations')
                            },
                        },
                        {
                            text: 'Revoke',
                            onClick: ({ data }) => {
                                this.triggerRevokeDialog(data)
                            },
                            isDisabled: () => {
                                return this.isLoading
                            },
                            isHidden: () => {
                                return !this.hasPermission('revokeConfigurations')
                            },
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef: { menuTabs: [] },
                sortModel: [
                    {
                        colId: 'dateUpdated',
                        sort: 'desc',
                    },
                ],
            },
        }
    },
    methods: {
        triggerRevokeDialog (data) {
            this.activeData = data
            this.dialog = {
                isOpen: true,
                content: `Are you sure that you want to revoke this configuration?`,
            }
        },
        revoke () {
            const url = CONFIG.API.ROUTES.PRODUCT_FEEDS.CONFIGURATIONS.REVOKE.replace('{configurationId}', this.activeData.configurationId)

            this.isLoading = true
            this.post(url, 'Revoke success').then(() => {
                this.$refs.table.refreshGrid()
            })
        },
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },

    },
}
</script>

<style lang="scss" scoped>
.c-configurations {
    padding: 50px;
}
</style>
